<script setup lang="ts">
import PerformanceBase from "@/components/PerformanceBase.vue";
import PrimaryButton from "@/components/utils/PrimaryButton/PrimaryButton.vue";
import { performanceHistory } from '@/lib/external-data/performanceHistory'
import formatRoi from "@/lib/formatRoi";
import { useLocalStorage } from "@vueuse/core";
import AnimatedBackground from "./AnimatedBackground.vue";
import useAosRefresh from "@/composables/useAosRefresh";
import {useI18n} from "vue-i18n";
const {t} = useI18n({
  useScope: 'global'
});
const visibleHistoryCount = 4

const selectedPerformanceDisplay = useLocalStorage < '%' | 'x' > ('selected-performance-display', 'x', { initOnMounted: true })

useAosRefresh()

</script>

<template>
  <PerformanceBase>
    <template #background>
      <AnimatedBackground />
    </template>
    <template #header >

      {{ t('bright_green_performances') }}

    </template>
    <template #default="{ selectedAudienceSize }" >
      <div class="container flex flex-col items-center">
        <div class="justify-between mt-7.5 gap-x-5 relative hidden lg:inline-flex">
          <div class="inset-0 absolute bottom-0 left-0 bg-gradient-to-t from-brand-background">
          </div>
          <div 
            v-for="(performance, index) in performanceHistory[selectedAudienceSize].slice(-visibleHistoryCount)" 
            :class="[
              'min-w-61.5 py-4 px-4.5 border-2 border-brand-stroke/10 rounded-3',
              (index === 0 || index === visibleHistoryCount - 1) && 'mt-7.5'
            ]"
          >
            <div class="flex items-end">
              <span class="font-bold leading-none">
                {{ performance.month }}
              </span>
              <span class="text-xs font-semibold opacity-40 ml-1.5 leading-none">
                {{ performance.year }}
              </span>
            </div>
            <div class="flex items-end my-4">
              <span class="font-bold text-3xl text-brand-gradient leading-none">
                {{ formatRoi(performance.pnl, selectedPerformanceDisplay) }}
              </span>
              <span class="font-bold text-sm opacity-40 ml-1.5 leading-none">
                <template v-if="selectedPerformanceDisplay === '%'" >
                  ROI
                </template>
                <template v-if="selectedPerformanceDisplay === 'x'" >
                  {{ t('on_capital') }}
                </template>  
              </span>
            </div>
            <span class="inline-block">
              {{ performance.winRate }}
              <span class="opacity-40">{{ t('win_rate') }}</span>
            </span>
            <div>
              {{ performance.avgTrade }}
              <span class="opacity-40"> {{ t('average_trade') }} ROI</span>
            </div>
          </div>
        </div>
        <div class="mt-15 lg:mt-1 relative w-full flex items-center justify-center -translate-y-1/2">
          <div class="absolute h-.5 w-full bg-gradient-to-r from-transparent via-brand-stroke/10 to-transparent">
          </div>
          <div class="relative">
            <PrimaryButton href="/performance">
              {{ t('see_dipsway_live_backtests') }}
            </PrimaryButton>
          </div>
        </div>
        <p class="max-w-74 text-brand-secondary leading-[150%] font-medium text-sm text-center">
          {{t('find_and_download')}}
        </p>
      </div>
    </template>
  </PerformanceBase>
</template>