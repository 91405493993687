<script setup lang="ts">

const uniqueComponentIdPrefix = "performance-preview-";

interface Point {
  duration: number;
  delay: number;
}

interface PointsGroup {
  pathId: string;
  points: Point[];
}

const points = [
  {
    pathId: uniqueComponentIdPrefix + 1,
    points: [
      {
        duration: 9000,
        delay: 0,
      },
      {
        duration: 9000,
        delay: 200,
      },
      {
        duration: 9000,
        delay: 500,
      },
      {
        duration: 9000,
        delay: 3000,
      },
      {
        duration: 7000,
        delay: 4500,
      },
      {
        duration: 9000,
        delay: 6000,
      },
    ],
  },
  {
    pathId: uniqueComponentIdPrefix + 2,
    points: [
      {
        duration: 5000,
        delay: 0,
      },
      {
        duration: 2600,
        delay: 1300,
      },
      {
        duration: 4000,
        delay: 2000,
      },
    ],
  },
  {
    pathId: uniqueComponentIdPrefix + 3,
    points: [
      {
        duration: 6000,
        delay: 0,
      },
      {
        duration: 4000,
        delay: 2300,
      },
      {
        duration: 4000,
        delay: 2000,
      },
      {
        duration: 8000,
        delay: 3000,
      },
    ],
  },
] as const satisfies PointsGroup[]

</script>

<template>
  <div class="h-full w-full grid place-content-center relative -z-1">
    <div
      class="h-160 w-auto aspect-video mx-auto relative flex items-center justify-center fade-t-48 contain-paint"
    >
      <!-- data paths -->
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1600 900"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="
          fill-rule: evenodd;
          clip-rule: evenodd;
          stroke-linecap: square;
          stroke-linejoin: round;
        "
      >

        <clipPath id="_clip1">
          <rect x="0" y="0" width="1600" height="900" />
        </clipPath>

        <g clip-path="url(#_clip1)" class="stroke-2 stroke-brand-stroke/5">
          <path
            :id="points[2].pathId"
            d="M259.914,-49.278l0,153.991c0,297.706 241.338,539.046 539.046,539.046c297.706,-0 539.045,-241.34 539.045,-539.046l-0,-153.991l-676.198,-0l-10.618,-71.605c133.127,-14.807 114.236,-140.509 -11.187,-119.698l-39.254,5.303l20.843,186l-74.878,-0l-29.032,-178.821l-31.112,-0.179l20.69,179l-151.258,-0c38.113,-72.448 63.652,-103.114 -0.727,-177.342l-68.558,7.342l4.344,170l-31.146,-0Z"
            style="fill: none; fill-rule: nonzero"
          />
          <path
            :id="points[1].pathId"
            d="M162.286,-83.278l0,199.416c0,352.2 285.516,637.714 637.715,637.714c352.2,0 637.716,-285.514 637.716,-637.714l-0,-199.416l-207.93,-0l-26.782,-170l-29.771,170l-51.042,-0l36.262,-163.231l-68.839,1.022l-31.807,79.25l-11.434,-78.607l-105.374,1.566l49.472,160l-93.493,-0l-14.187,-45.559l-39.406,-24.358l53.593,-95.88l-118.96,55.475l49.384,84.916l-70.092,-25.594l-11.762,51l-583.263,-0Z"
            style="fill: none; fill-rule: nonzero"
          />
          <path
            :id="points[0].pathId"
            d="M60.5,-83.278l-0,259.317c-0,379.921 307.986,687.907 687.907,687.907l103.186,0c379.921,0 687.907,-307.986 687.907,-687.907l-0,-259.317l-227.303,-0l30.555,-184.339l-44.401,76.717l-101.981,-76l93.201,105.622l-22.403,75l-1206.67,3Z"
            style="fill: none; fill-rule: nonzero"
          />
          <g>
            <!-- circles -->
            <g v-for="(group, index) in points" :key="index">
              <circle
                v-for="(point, index) in group.points"
                :key="index"
                :style="{
                  offsetPath: `url(#${group.pathId})`,
                  '--points-animation-delay': `${point.delay}ms`,
                  '--points-animation-duration': `${point.duration}ms`,
                }"
                r="4"
                cx="0"
                cy="0"
                class="fill-brand-gradient animate-circle"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<style scoped>
.animate-circle {
  animation-name: animate-circle;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-delay: var(--points-animation-delay);
  animation-duration: var(--points-animation-duration);
}

@keyframes animate-circle {
  0% {
    opacity: 0%;
    offset-distance: 0%;
  }

  5% {
    opacity: 100%;
  }

  95% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
    offset-distance: 100%;
  }
}
</style>
